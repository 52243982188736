




function Item(props){
    return(
        <div className='project-item'>
            <div className="project-info flex-col">
              <h1>{props.title}</h1>
              <h2>{props.journal}</h2>
              <a href={props.link}  target="_blank" rel="noreferrer"> Go to Paper </a>
              <h5> {props.authors} </h5>
            </div>
          </div>
    );
}

export default Item;


//
// <div className="project-img">
//   <img src={pic} alt="img"/>
// </div>
