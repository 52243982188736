import React from 'react';
import './css/Contact.css';
import { useState, useEffect } from 'react';




function Contact(props) {


  const [language, setLang]= useState(props.language)

  useEffect (() => {
    setLang(props.language);
  },[props.language])

  const contact_data =  {"FR" : {"title": "Contactez Moi!", "l1":"Votre Nom:" ,'l2': "Votre Email:" , 'l3': "Votre Message:", 'bttn': "Envoyer"} ,
   "EN" : {"title": "CONTACT ME!", "l1":"Your Name:" ,'l2': "Your Email:" , 'l3': "Your Message:", 'bttn': "Send"}}
  const contact_filt = contact_data[language]

  const contact_title = contact_filt.title.split(' ')


  return (
    <div className="Contact container flex-col" id='Contact-ID'>
      <div className="container-out flex-col">
        <div><h1 className="section-title">{contact_title[0]} <span>{contact_title[1]}</span></h1></div>
          <div className="container-in">
            <form className="contact-form" name="contact" method="POST">
              <input type="hidden" name="form-name" value="contact" />
              <p> <label> {contact_filt.l1} <input type="text" name="name" /></label></p>
              <p> <label> {contact_filt.l2} <input type="email" name="email" /></label></p>
              <p><label>{contact_filt.l3} <textarea name="message"></textarea></label> </p>
              <p><button type="submit">{contact_filt.bttn}</button></p>
            </form>
          </div>
        </div>
      </div>
  );
}

export default Contact;
