import React from 'react';
import pic from './img/logo.jpg'

function Footer() {
  return (
    <div className="Footer flex-row">
        <div className='footer-left'>
          <img src={pic} alt="logo-kea-scientific"/>
        </div>
        <div className='footer-middle'>
          <p> © 2020 Clotilde Jumelle - Kea Scientific </p>
        </div>
        <div className='footer-right'>
          <p className='copy'> Website Developed by Milind Patel <a className="pretty-link" href="https://milind-dev.netlify.app" target="_blank" rel="noopener noreferrer" > (Visit Site)</a> </p>
        </div>
    </div>
  );
}

export default Footer;
