import React from 'react';
import './css/About.css';
import { useState, useEffect } from 'react';



function About(props) {

  //  useState using props coming from the parent

  const [language, setLang]= useState(props.language)


  // useEffect to apply

  // setLang(props.language) ////

  // update this comp when props from parent change change

  useEffect (() => {
    setLang(props.language);
  },[props.language])


  // Defining data  in two languages and filtering with language



   const about_data =  {"FR" : {"l1": "À propos de", "l2":"Ingénieure biomédicale, docteur (PhD) et consultante indépendante en rédaction scientifique et réglementaire.",
   "l3":["(X)Ingénieure biomédicale:(X) J’ai été diplômée en 2009 de l’Institut Supérieur d'Ingénieurs de Franche-Comté (ISIFC), une école d'ingénieur spécialisée en (X)Dispositif Médical(X), et notamment ses aspects (X)techniques et réglementaires(X).", "Lors de ma dernière année d'école d’ingénieur, j’ai aussi validé un (X)Master 2 en Ingénierie Cellulaire et Tissulaire(X) à l'université de Franche-Comté." ,"J’ai poursuivi mon parcours par un (X)Doctorat en Sciences (PhD), spécialité Biologie Cellulaire et Moléculaire(X) en laboratoire de recherche." , "En parallèle de mon activité de recherche, j’ai obtenu un diplôme en (X)Interprétation des Essais Thérapeutiques(X), ainsi qu’un diplôme en (X)Expérimentation Animale(X) de l’Université de Lyon.", "J’ai ensuite été recrutée par (X)l'Université de Harvard aux Etats-Unis(X), ce qui m’a permis d’approfondir pendant deux ans mes compétences en recherche et en rédaction scientifique dans un environnement multiculturel."]} ,

    "EN" : {"l1": "About", "l2":"Biomedical engineer, PhD, and freelance consultant in scientific and regulatory writing.",
    "l3":["(X)Biomedical engineer:(X) I graduated in 2009 from the Institut Supérieur d'Ingénieurs de Franche-Comté (ISIFC), an French engineering school specializing in (X)Medical Devices(X), and especially its (X)technical and regulatory(X) aspects." , "During my last year of engineering school, I also obtained a (X)Master degree in Cell and Tissue Engineering(X) from the Franche-Comté University." , "I continued my education by a (X)PhD in sciences, specialized in Cell and Molecular Biology(X) in an academic research lab.", "In parallel with my research activity, I obtained a degree in (X)Interpretation of Clinical Trials(X), as well as a degree in (X)Animal Experimentation(X) from Lyon University." , "Then, I had the opportunity to work for 2 years at (X)Harvard University in the United States(X), where I was able to go into my skills and research and scientific writing more in depth, in a multicultural environment."]
  }
}


  const about_filt = about_data[language]

  // const about_bullets = about_filt.l3.split("(X)")

  const about_bullets = about_filt.l3


  // return the div with new/ updated props data


  return (
          <div className="About container flex-col" id='About-ID'>
            <div className="about-inside">
              <h1 className="section-title">{about_filt.l1}<span>  Clotilde</span></h1>
              <h2>{about_filt.l2}</h2>
              <p>

              <ul>
                {
                  about_bullets.map(bullet => (
                      <li>
                          {bullet.split("(X)")[0]}<b>{bullet.split("(X)")[1]}</b>{bullet.split("(X)")[2]}<b>{bullet.split("(X)")[3]}</b>{bullet.split("(X)")[4]}<b>{bullet.split("(X)")[5]}</b>{bullet.split("(X)")[6]}

                      </li>
                    ))
                }
              </ul>


              </p>
            </div>

            <div className="more-profile">
              <a href="https://www.linkedin.com/in/clotilde-jumelle-802366a6/"  target="_blank" rel="noreferrer"> {language === 'EN' ? 'Full Profile on LinkedIn': 'Profil Complet sur LinkedIn'}</a>
            </div>

            <div className='dummy-div' id="Portfolio">
            </div>

          </div>
  );
}

export default About;
