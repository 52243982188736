import React from 'react';
import Item from './Item';
import { useState, useEffect } from 'react';
import './css/Portfolio.css';


const papers = [
  {
    "key": 1,
    "Title": "Growth factor-eluting hydrogels for management of corneal defects.",
    "Authors": "Clotilde Jumelle, Ehsan Shirzaei Sani, YukakoTaketani, AnnYung, Fanny Gantin, Sunil K.Chauhan, Nasim Annabi, RezaDana.",
    "Journal": "Materials Science and Engineering: C Volume 120, January 2021, 11179",
    "DOI": "",
    "Link": "https://www.sciencedirect.com/science/article/abs/pii/S0928493120337097?dgcid=rss_sd_all"
  },
  {
    "key": 2,
    "Title": "Advances and limitations of drug delivery systems formulated in eye drops.",
    "Authors": "Jumelle C, Gholizadeh S, Annabi N, Dana R.",
    "Journal": "Journal of Controlled Release (2020 Feb 3; 321:1-22)",
    "DOI": "10.1016/j.jconrel.2020.01.057.",
    "Link": "https://www.sciencedirect.com/science/article/abs/pii/S0168365920300778"
  },
  {
    "key": 3,
    "Title": "Comparison of four methods of surface roughness assessment of corneal stromal bed after lamellar cutting.",
    "Authors": "Jumelle C, Hamri A, Egaud G, Mauclair C, Reynaud S, Dumas V, Pereira S, Garcin T, Gain P, Thuret G.",
    "Journal": "Biomedical Optic Express (2017 Oct 12; 8(11):185-191)",
    "DOI": "10.1364/BOE.8.004974.",
    "Link": "https://www.osapublishing.org/boe/fulltext.cfm?uri=boe-8-11-4974&id=375182"
  },
  {
    "key": 4,
    "Title": "Considering 3D topography of endothelial folds to improve cell count of organ cultured corneas.",
    "Authors": "Jumelle C, Garcin T, Gauthier AS, Glasson Y, Bernard A, Gavet Y, Klossa J, He Z, Acquart S, Gain P, Thuret G.",
    "Journal": "Cell and Tissue Banking. (2017 Jun; 18(2):185-191)",
    "DOI": "10.1007/s10561-017-9624-7.",
    "Link": "https://link.springer.com/article/10.1007/s10561-017-9624-7"
  },
  {
    "key": 5,
    "Title": "Delivery of macromolecules into the endothelium of whole ex vivo human cornea by femtosecond laser-activated carbon nanoparticles.",
    "Authors": "Jumelle C, Mauclair C, Houzet J, Bernard A, He Z, Forest F, Peoc’h M, Acquart S, Gain P, Thuret G.",
    "Journal": "British Journal of Ophthalmology (2016 Aug; 100(8):1151-6).",
    "DOI": "10.1136/bjophthalmol-2015-307610.",
    "Link": "https://bjo.bmj.com/content/100/8/1151"
  },
  {
    "key": 6,
    "Title": "Delivery of Molecules into Human Corneal Endothelial Cells by Carbon Nanoparticles Activated by Femtosecond Laser.",
    "Authors": "Jumelle C, Mauclair C, Houzet J, Bernard A, He Z, Forest F, Peoc’h M, Acquart S, Gain P, Thuret G",
    "Journal": "Published to Plos One (2015 Jul 2;10(7):e0132023)",
    "DOI": "10.1371/journal.pone.0132023.",
    "Link": "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0132023"
  }
]



const portfoliItemsComp = papers.map(item => <Item title={item.Title} authors={item.Authors} journal={item.Journal} doi={item.DOI} link={item.Link} key={item.key}/>)


function Portfolio(props) {

  const [language, setLang]= useState(props.language);

  useEffect (() => {
    setLang(props.language);
  },[props.language])

  const title = { "EN":{ "title" : "Scientific Contributions" }, "FR": { "title" : "Contributions Scientifiques"} };

  const title_filt = title[language];
  const title_split = title_filt.title.split(' ')

  return (
    <div className="Portfolio container flex-col" id='Portfolio-ID'>

      <div className="projects-header">
        <h1 className="section-title">{title_split[0]} <span>{title_split[1]}</span></h1>
      </div>

      <div className="all-projects">
        {portfoliItemsComp}
      </div>

      <div className="more-papers">
        <a href="https://scholar.google.com/citations?user=_AvUQdsAAAAJ&hl=fr&oi=ao"  target="_blank" rel="noreferrer">{language === 'EN' ? 'See Full List on Google Scholar': 'Voir la Liste Complète sur Google Scholar' }</a>
      </div>

      <div className='dummy-div' id="Clients">
      </div>
    </div>
  );
}

export default Portfolio;
