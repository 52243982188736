import React from 'react';
import './css/Header.css';
import { useState, useEffect } from 'react';
import pic from './img/logo.jpg'; // gives image path
import menu from './img/menu@1x.png';
import close from './img/close@1x.png';


function Header(props) {

  //  useState using props coming from the parent

  const [language, setLang] = useState(props.language)

  // useEffect to apply / update this comp when props from parent change

  useEffect (() => {
    setLang(props.language);
  },[props.language])


  const header_data =  {"FR" : {"l1": "Accueil", "l2":"Services", "l3":"À propos", "l4":"Clients", "l5":"Contact"} ,
   "EN" : {"l1": "Home", "l2":"Services", "l3":"About", "l4":"Clients", "l5":"Contact"}}

  const header_filt = header_data[language]


  // return the div with new/ updated props data


  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const toggleMenu = () => {
    setActive(false);
  }



  return (

    <div className="Header flex-col" id='Header' style={{backgroundColor:props.headerColor}} >
        <div className="nav-bar">
          <div className="brand">
            <a href="#Welcome">
              <img src={pic} alt="logo-kea-scientific"/>
            </a>
          </div>

          <select value={props.language} disabled={false} onChange={e => props.changeLang(e.target.value)}>
          <option value="FR">Français</option>
          <option value="EN">English</option>
          </select>

          <div className="nav-list">
            <div className={isActive ? 'hamburger active':'hamburger' } onClick={toggleClass} >
              <img src={isActive ? close : menu } alt="menu-icon"/>

            </div>
            <ul className={isActive ? 'active': null} onClick={toggleMenu}>
              <li><a href= "#Home" data-after="Home" > {header_filt.l1} </a></li>
              <li><a href='#Services' data-after="Services" > {header_filt.l2} </a></li>
              <li><a href='#About' data-after="About" > {header_filt.l3} </a></li>
              <li><a href='#Clients' data-after="Clients" > {header_filt.l4} </a></li>
              <li><a href="#Contact" data-after="Contact" > {header_filt.l5} </a></li>
            </ul>
          </div>
        </div>
      </div>

  );
}

export default Header;
