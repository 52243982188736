import React from 'react';
import './css/Portfolio.css';
import santen from './img/Santen.png';
import chameleon from './img/Chameleon.png';
import alcon from './img/Alcon.png';
import quantel from './img/Quantel.png';


function ClientItem (props) {
  return (
    <div className="client-logo-cont flex-col">
      <img src={props.logo} alt={`client-${props.name}`}/>
    </div>
  )
}

const clients_list = [
  {
    name: "Santen",
    logo:santen
  },
  {
    name: "Chameleon",
    logo:chameleon
  },
  {
    name: "Alcon",
    logo:alcon
  },
  {
    name: "Quantel",
    logo:quantel
  },
]


function Clients(props) {

  return (
    <div className="Clients container flex-col" id='Clients-ID'>
      <div className="projects-header">
        <h1 className="section-title">Cl<span>i</span>ents</h1>
      </div>

      <div className="clients flex-row">

        {
          clients_list.map ( item => <ClientItem name={item.name} logo={item.logo}/ > )
        }

      </div>

      <div className='dummy-div' id="Contact">
      </div>
    </div>
  );
}

export default Clients;
