import React from 'react';
import './css/Services.css';
import { useState, useEffect } from 'react';

import logo1 from './img/ScientificLogo.svg';
import logo2 from './img/RegulatoryLogo.svg';
import logo3 from './img/PopularizationLogo.svg';
import logo4 from './img/ConsultingLogo.svg';

const services_data = {
  "FR": [
    {
      "key": 1,
      "title": "Rédaction scientifique",
      "tagline": "Créer du contenu scientifique et technique de haut niveau.",
      "descr": "Auteure de plusieurs articles d’état de l’art et de recherche publiés dans des revues scientifiques de haut rang, j’offre mes services pour la (X)création de contenu scientifique et technique de haut niveau(X), sous différents formats:",
      "bullets":"Etats de l’art (étude bibliographique) (X)Analyse critique d’articles (X)Analyse de données (X)Diapositives pour présentations orales (X)Publications pour revues scientifiques, évaluées par les pairs. (X)Brochures, guides et manuels d’instructions (X)Et plus, selon vos besoins.",
      "icon": logo1,

    },
    {
      "key": 2,
      "title": "Rédaction Réglementaire",
      "tagline": "Répondre aux exigences des autorités de santé",
      "descr": "Ma formation en (X)Interprétation des Essais Cliniques(X) ainsi que mon (X)expertise scientifique et mes connaissances en réglementations des dispositifs médicaux(X) me permettent d’apporter au client des compétences spécifiques pour la rédaction de (X)documents réglementaires(X), tels que:",
      "bullets":"Rapports d'Évaluation Clinique (X)Rapports d'Évaluation Biologiques et Toxicologiques (X)Analyses de risques (X)Réponses réglementaires aux autorités de santé (X)Et plus, selon vos besoins",
      "icon": logo2,

    },
    {
      "key": 3,
      "title": "Vulgarisation Scientifique",
      "tagline": "Diffuser votre contenu scientifique au grand public",
      "descr": "Ayant travaillé principalement dans des (X)environnements multiculturels(X), je suis capable de (X)diffuser, résumer et simplifier(X) votre contenu technique et scientifique à destination du grand public, et ce, sous différents formats, tels que:",
      "bullets":"Brochures, guides et manuels d’utilisation (X)Articles pour journaux de vulgarisation scientifique (X)Articles de blogs (X)Posts sur réseaux sociaux (X)Et plus, selon vos besoins",
      "icon": logo3,

    },
    {
      "key": 4,
      "title": "Conseil",
      "tagline": "Design expérimental et gestion de projet",
      "descr": "En laboratoires de recherche, j’ai développé d’importantes compétences en (X)design expérimental et gestion de projet(X), notamment dans le domaine de l’ophtalmologie. Mes services incluent:",
      "bullets":"Mise en place de protocoles expérimentaux (tests in vitro, ex vivo et in vivo) selon les Bonnes Pratiques de Laboratoire (BPL) (X)Sélection du matériel et réactifs nécessaires aux tests (X)Optimisation des ressources, du temps et du personnel (X)Assurer le bon déroulement des tests en respectant les deadlines (X)Analyse de résultats et rédaction de rapports (X)Troubleshooting (X)Et plus, selon vos besoins",
      "icon": logo4,

    }
  ],
  "EN": [
    {
      "key": 1,
      "title": "Scientific Writing",
      "tagline": "Creating high-quality scientific and technical content.",
      "descr": "Author of several review and research articles published in peer-reviewed scientific journals, I offer my services for the (X)creation of high-quality scientific and technical content(X), through various formats:",
      "bullets":"Literature reviews (X)Critical analysis of articles (X)Data analysis (X)Slides for oral communications (X)Publications for scientific peer-reviewed journals (X)Brochures, guides and use instructions (X)And more according to your needs",
      "icon": logo1,

    },
    {
      "key": 2,
      "title": "Regulatory Writing",
      "tagline": "Addressing the requirements of health authorities",
      "descr": "My qualification in (X)Interpretation of Clinical Trials(X) along with my (X)scientific expertise and knowledges in regulations of medical devices(X) allows me to provide the customer with specific skills for the writing of (X)regulatory documents(X), such as:",
      "bullets":"Clinical Evaluation Reports (X)Biological Evaluation and Toxicology Reports (X)Risk management (X)Regulatory responses to health authorities (X)And more, according to your needs",
      "icon": logo2,

    },
    {
      "key": 3,
      "title": "Scientific Popularization",
      "tagline": "Diffusing your scientific content to the general public",
      "descr": "Working primarily in (X)multicultural environments(X), I am able to (X)diffuse, summarize, and simplify(X) your technical and scientific content in a way that relates and translates through a multitude of social barriers and in different formats, including:",
      "bullets":"Brochures, guides and use instructions (X)Articles for journals of science popularization (X)Blog posts (X)Social media posts (X)And more according to your needs",
      "icon": logo3,

    },
    {
      "key": 4,
      "title": "Consulting",
      "tagline": "Experimental design and project management",
      "descr": "In research laboratories, I developed important skills in (X)experimental design and scientific project management(X), specifically in ophthalmology. My services include:",
      "bullets":"Set up experimental protocols (in vitro, ex vivo and in vivo testing) according to the Good Laboratory Practices (GLP) (X)Selection of materials and reagents required for the tests (X)Optimization of resources, time and personnel (X)Oversee the smooth running of the tests with respect of the deadlines (X)Results analysis and report writing (X)Troubleshooting (X)And more according to your needs",
      "icon": logo4,

    }
  ],
}

// const services_types = {
//   "FR" : { title: "Types De Support" , type1: 'Decks de Diapositives' , type2: "Revoir les Articles"  },
//   "EN" : { title: "Format Types" , type1: 'Slide Decks' , type2: "Review Articles"}
// }


function ServiceItem(props) {

  const [isActive, setActive] = useState( (props.activeTab===props.num) ? true : false );
  console.log(isActive);

  useEffect(() => {
    setActive((props.activeTab===props.num) ? true : false )
  },[props.activeTab, props])


  return(


    <div className={isActive ? 'item-container flex-col active':'item-container flex-col'} onClick={() => props.changeActiveTab(props.num)}>
      <img src={props.icon} height={40} width={40} alt="Service Item Icon"/>
      <h2>{props.title}</h2>
    </div>

);
}

// function SupportItem(props) {
//
//   return(
//
//     <div className="support-item-container flex-row">
//       <img src={props.icon} height={40} width={40} alt="Service Item Icon"/>
//       <h2>{props.title}</h2>
//     </div>
//
// );
// }


class Services extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      language: props.language,
      activeTab: 1
    }
    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  changeActiveTab = (tab) => {this.setState({activeTab:tab});
  }

  render () {

    const data_items = services_data[this.props.language];
    // const type_items = services_types[this.props.language];

    const ServiceItems = data_items.map( item => <ServiceItem title={item.title} icon={item.icon} key={item.key} num={item.key} activeTab={this.state.activeTab} changeActiveTab={this.changeActiveTab}/>);

    const service_bottom = data_items[this.state.activeTab-1]
    const bullets = service_bottom.bullets;
    const bullets_split = bullets.split("(X)");

    const service_descr = service_bottom.descr.split("(X)")
    console.log(service_descr);

    return (
      <div className="Services container flex-col" id='Services-ID'>
        <div className="service-top">
          <h1 className="section-title"> Serv<span>i</span>ces</h1>
        </div>
        <div className='service-title flex-row' id='service_tag'>
          {ServiceItems}
        </div>

        <div className="service-bottom flex-col">
          <div className='service-item-heading flex-row'>
            <img src={service_bottom.icon} height={80} width={80} alt="Service Item Icon"/>
            <h2>{service_bottom.title}</h2>
          </div>

          <div className='service-item-content flex-col'>

            <h2> {service_bottom.tagline} </h2>
            <div className='service-item-content-right'>
                <p>{service_descr[0]}<span>{service_descr[1]}</span>{service_descr[2]}<span>{service_descr[3]}</span>{service_descr[4]}<span>{service_descr[5]}</span></p>
                <ul>
                  {
                    bullets_split.map(bullet => (
                        <li>
                          {bullet}
                        </li>
                      ))
                  }
                </ul>
            </div>
          </div>
        </div>

      { /* <div className="support-type flex-col">
          <h1> Types of Support </h1>
          <div className="support-type-inner flex-row">
            <SupportItem title={type_items.type1} icon={logo1}/>
            <SupportItem title={type_items.type2} icon={logo1}/>
          </div>
        </div> */}


        <div className='dummy-div' id="About">
        </div>

      </div>
    );
  }
}




// {
//
//   //  useState using props coming from the parent
//
//   // const [language, setLang]= useState(props.language)
//
//   const [activeTab, setActiveTab]= useState(1)
//
//   // setLang(props.language) ////
//   // useEffect to apply
//   // update this comp when props from parent change change
//
//   useEffect (() => {
//     setLang(props.language);
//   },[props.language , activeTab])
//
//   const makeActive = () => {
//     setActiveTab(props.key);
//   };

  // Fitering  data  by two languages from dat defined above


export default Services;
