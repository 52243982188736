import React from 'react';
import './css/Home.css';
import pic from './img/profile.jpg'; // gives image path
import { useState, useEffect } from 'react';
import CV from './img/Full_Resume_Clotilde_JUMELLE.pdf';



function Home(props) {

  //  useState using props coming from the parent

  const [language, setLang]= useState(props.language)

  // update this comp when props from parent change change

  useEffect (() => {
    setLang(props.language);
  },[props.language])


  // Defining data  in two languages and filtering with language

  const home_data =  {"EN" : {"l1": "A ", "l2":"Biomedical Engineer","l3":"and ","l4":"PhD", "l5":"at your service", 'bttn1': "Services" , 'bttn2': "View CV"} ,
   "FR" : {"l1": "Une ", "l2":"Ingénieure Biomédicale","l3":"et ","l4":"Docteure (PhD)", "l5":"à votre service", 'bttn1': "Services" , 'bttn2': "Voir CV"}}
  const home_filt = home_data[language]

  const home2_data =  {"FR" : {"l1": "À propos", "l2":"Avec 8 ans d’expérience en recherche académique en France et aux Etats-Unis, j’ai développé des compétences en méthodologie et rigueur scientifique, ainsi qu’en analyse critique.  En tant que consultante indépendante, mon objectif est d’apporter mon expertise et de développer des relations de confiance.",
  "l3":"Dr. Clotilde Jumelle"} ,
   "EN" : {"l1": "About", "l2":"With 8 years of academic research in both France and the United States, I developed skills in scientific methodology, rigor and critical analysis.  As an independent consultant, my goal is to provide my expertise and establish trust-based relationships.",
   "l3":"Dr. Clotilde Jumelle"}}
  const home2_filt = home2_data[language]




  return (
      <div className="Home_Main flex-col">
          <div className="Home container"  id='Home-ID'>
            <div className="intro">
              <h1 className='flex-row'> <b>{home_filt.l1} </b>{home_filt.l2} <span></span></h1>
              <h1 className='flex-row'> <b>{home_filt.l3} </b> {home_filt.l4} <span></span></h1>
              <h1 className='flex-row'>{home_filt.l5} <span></span></h1>
              <div className="home-bttns">
                <a href='#Services' type="button" className="cta">{home_filt.bttn1}</a>
                <a href={CV} target="_blank" rel="noreferrer" type="button" className="cta">{home_filt.bttn2}</a>
              </div>
            </div>

            <div className='dummy-div' id="Home">
            </div>
          </div>

          <div className="Home2 container flex-row" id='Home2'>
            <div className="col-left">
              <div className="home2-img">
                <img src={pic} alt="Profile-Picture"/>
              </div>
            </div>
            <div className="col-right">
              <p>{`"${home2_filt.l2}"`} </p>
              <h2>{home2_filt.l3}</h2>
            </div>
          </div>

          <div className='dummy-div' id="Services">
          </div>

        </div>
  );
}

export default Home;
